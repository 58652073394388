import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as approvalService from "@/redux/services/approvalService"

import { Approval, Check, Comment } from "@/models/approvals.model"
import { PHASearchData } from "@/models/pha.model"

interface ApprovalState {
  history: Approval[]
}

const initialState: ApprovalState = {
  history: []
}

export const postApprovalData = createAsyncThunk("approval/data", async (data: PHASearchData) => {
  return await approvalService.postApprovalData(data)
})

export const postCheck = createAsyncThunk("approval/check", async (data: Check) => {
  return await approvalService.postCheck(data)
})

export const postComment = createAsyncThunk("approval/update", async (data: Comment) => {
  return await approvalService.postComment(data)
})

const approvalSlice = createSlice({
  name: "approval",
  initialState: initialState,
  reducers: {}
})

export default approvalSlice.reducer