import { PHASearchData } from "@/models/pha.model"
import { FileForm, FileRes, FileOneRes, FileRemove } from "@/models/storages.model"
import { PHAClient } from "@/utils/axios"

export const postFileData = async (data: PHASearchData): Promise<FileRes> => {
  const response = await PHAClient.post(`/api/upload/data`, data)
  return response.data
}

export const removeFile = async (data: FileRemove): Promise<void> => {
  const response = await PHAClient.post(`/api/upload/remove`, data)
  return response.data
}

export const postUpload = async (data: FileForm): Promise<FileOneRes> => {
  const response = await PHAClient.post(`/api/upload/create`, data)
  return response.data
}