import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as statusService from "@/redux/services/statusService"
import { Status } from "@/models/statuses.model"
import { RootState } from "@/redux/store"

interface StatusState {
  statuses: Status[]
}

const initialState: StatusState = {
  statuses: []
}

export const getStatus = createAsyncThunk("status/all", async () => {
  return await statusService.getStatus()
})

const statusSlice = createSlice({
  name: "status",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.statuses = action.payload.result
    })
  },
})

export const statusSelector = (store: RootState): Status[] => store.status.statuses

export default statusSlice.reducer