import { CompanyRes, CostCenterRes, EmployeeRes, ProductHierarchiesRes, Search } from "@/models/masterdatas.model"
import { PHAClient } from "@/utils/axios"

export const getEmployee = async (): Promise<EmployeeRes> => {
  const response = await PHAClient.get(`/api/masterdata/employee`)
  return response.data
}

export const getCompany = async (): Promise<CompanyRes> => {
  const response = await PHAClient.get(`/api/masterdata/company`)
  return response.data
}

export const getProductHier = async (): Promise<ProductHierarchiesRes> => {
  const response = await PHAClient.get(`/api/masterdata/hier`)
  return response.data
}

export const getCostCenter = async (): Promise<CostCenterRes> => {
  const response = await PHAClient.get(`/api/masterdata/costcenter`)
  return response.data
}

export const postMasterSearch = async (data: Search): Promise<void> => {
  const response = await PHAClient.post(`/api/masterdata/search`, data)
  return response.data
}