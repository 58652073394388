import { ApprovalRes, Check, Comment } from "@/models/approvals.model"
import { AssessmentOneRes } from "@/models/assessments.model"
import { PHASearchData } from "@/models/pha.model"
import { PHAClient } from "@/utils/axios"

export const postApprovalData = async (data: PHASearchData): Promise<ApprovalRes> => {
  const response = await PHAClient.post(`/api/approval/data`, data)
  return response.data
}

export const postCheck = async (data: Check): Promise<AssessmentOneRes> => {
  const response = await PHAClient.post(`/api/approval/check`, data)
  return response.data
}

export const postComment = async (data: Comment): Promise<AssessmentOneRes> => {
  const response = await PHAClient.post(`/api/approval/update`, data)
  return response.data
}