import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as occurrenceService from "@/redux/services/occurrenceService"
import { Occurrence } from "@/models/occurrences.model"
import { RootState } from "@/redux/store"

interface OccurrenceState {
  occurrences: Occurrence[]
}

const initialState: OccurrenceState = {
  occurrences: []
}

export const getOccurrence = createAsyncThunk("occurrence/all", async () => {
  return await occurrenceService.getOccurrence()
})

const occurrenceSlice = createSlice({
  name: "occurrence",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOccurrence.fulfilled, (state, action) => {
      state.occurrences = action.payload.result
    })
  },
})

export const occurrenceSelector = (store: RootState): Occurrence[] => store.occurrence.occurrences

export default occurrenceSlice.reducer