import { AssessmentDupRes, AssessmentDuplicate, AssessmentForm, AssessmentHistory, AssessmentHistoryRes, AssessmentOneRes, AssessmentRes, AssessmentUpdateForm, SearchData } from "@/models/assessments.model"
import { DashboardRes, DashboardSearch } from "@/models/evaluations.model"
import { PHASearchData } from "@/models/pha.model"
import { PHAClient } from "@/utils/axios"

export const postAssessmentData = async (data: PHASearchData): Promise<AssessmentOneRes> => {
  const response = await PHAClient.post(`/api/assessment/data`, data)
  return response.data
}

export const postAssessmentSearch = async (data: SearchData): Promise<AssessmentRes> => {
  const response = await PHAClient.post(`/api/assessment/search`, data)
  return response.data
}

export const postAssessment = async (data: AssessmentForm): Promise<AssessmentOneRes> => {
  const response = await PHAClient.post(`/api/assessment/create`, data)
  return response.data
}

export const postAssessmentDuplicate = async (data: AssessmentDuplicate): Promise<AssessmentDupRes> => {
  const response = await PHAClient.post(`/api/assessment/duplicate`, data)
  return response.data
}

export const postAssessmentHistory = async (data: AssessmentHistory): Promise<AssessmentHistoryRes> => {
  const response = await PHAClient.post(`/api/assessment/history`, data)
  return response.data
}

export const putAssessment = async (data: AssessmentUpdateForm): Promise<AssessmentOneRes> => {
  const response = await PHAClient.put(`/api/assessment/update`, data)
  return response.data
}

export const postDashboard = async (data: DashboardSearch): Promise<DashboardRes> => {
  const response = await PHAClient.post(`/api/assessment/dashboard`, data)

  return response.data
}

export const sendReview = async (data: PHASearchData): Promise<void> => {
  const response = await PHAClient.post(`/api/assessment/review`, data)
  return response.data
}