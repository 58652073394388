import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as severityService from "@/redux/services/severityService"
import { Severity } from "@/models/severities.model"
import { RootState } from "@/redux/store"

interface SeverityState {
  severities: Severity[]
}

const initialState: SeverityState = {
  severities: []
}

export const getSeverity = createAsyncThunk("severity/all", async () => {
  return await severityService.getSeverity()
})

const severitySlice = createSlice({
  name: "severity",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSeverity.fulfilled, (state, action) => {
      state.severities = action.payload.result
    })
  },
})

export const severitySelector = (store: RootState): Severity[] => store.severity.severities

export default severitySlice.reducer