import { DashboardRes, DashboardSearch, EvaluateApproveForm, EvaluateProductForm, EvaluateProductOneRes, EvaluateProductRes, EvaluateRiskOneRes, EvaluateRiskRes, ListProductRes, MyProductReq, MyProductRes, ReviewProductReq, ReviewProductRes, UpdateCellEvaluation, UpdateEvaluateProductForm } from "@/models/evaluations.model"
import { DataRes, PHASearchData } from "@/models/pha.model"
import { PHAClient } from "@/utils/axios"

export const getProductList = async (): Promise<ListProductRes> => {
  const response = await PHAClient.get(`/api/evaluation/listproduct`)
  return response.data
}

export const postEvaluateDashboard = async (data: DashboardSearch): Promise<DashboardRes> => {
  const response = await PHAClient.post(`/api/evaluation/dashboard`, data)
  return response.data
}

export const postEvaluateSearch = async (data: PHASearchData): Promise<EvaluateProductOneRes> => {
  const response = await PHAClient.post(`/api/evaluation/search`, data)
  return response.data
}

export const postEvaluateHistory = async (data: PHASearchData): Promise<EvaluateProductRes> => {
  const response = await PHAClient.post(`/api/evaluation/history`, data)
  return response.data
}

export const postEvaluateProduct = async (data: EvaluateProductForm): Promise<DataRes> => {
  const response = await PHAClient.post(`/api/evaluation/product`, data)
  return response.data
}

export const postEvaluateRisk = async (data: PHASearchData): Promise<EvaluateRiskRes> => {
  const response = await PHAClient.post(`/api/evaluation/risk`, data)
  return response.data
}

export const postEvaluateApproval = async (data: PHASearchData): Promise<EvaluateProductOneRes> => {
  const response = await PHAClient.post(`/api/evaluation/approval`, data)
  return response.data
}

export const postCellEvaluation = async (data: PHASearchData): Promise<EvaluateRiskOneRes> => {
  const response = await PHAClient.post(`/api/evaluation/cell`, data)
  return response.data
}

export const postEvaluateReview = async (data: UpdateEvaluateProductForm): Promise<void> => {
  const response = await PHAClient.post(`/api/evaluation/review`, data)
  return response.data
}

export const putEvaluateUpdate = async (data: UpdateEvaluateProductForm): Promise<void> => {
  const response = await PHAClient.put(`/api/evaluation/update`, data)
  return response.data
}

export const putEvaluateApprove = async (data: EvaluateApproveForm): Promise<void> => {
  const response = await PHAClient.put(`/api/evaluation/approve`, data)
  return response.data
}

export const putCellEvaluation = async (data: UpdateCellEvaluation): Promise<void> => {
  const response = await PHAClient.put(`/api/evaluation/cell`, data)
  return response.data
}

export const postMyProduct = async (data: MyProductReq): Promise<MyProductRes> => {
  const response = await PHAClient.post(`/api/evaluation/myproduct`, data)
  return response.data
}

export const postReviewProduct = async (data: ReviewProductReq): Promise<ReviewProductRes> => {
  const response = await PHAClient.post(`/api/evaluation/clone`, data)
  return response.data
}