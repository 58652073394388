import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"

import awsReducer from "@/redux/slices/awsSlice"
import approvalReducer from "@/redux/slices/approvalSlice"
import topicReducer from "@/redux/slices/topicSlice"
import statusReducer from "@/redux/slices/statusSlice"
import severityReducer from "@/redux/slices/severitySlice"
import riskReducer from "@/redux/slices/riskSlice"
import occurrenceReducer from "@/redux/slices/occurrenceSlice"
import assessmentReducer from "@/redux/slices/assessmentSlice"
import evaluationReducer from "@/redux/slices/evaluationSlice"
import masterdataReducer from "@/redux/slices/masterdataSlice"
import storageReducer from "@/redux/slices/storageSlice"
import reportReducer from "@/redux/slices/reportSlice"

const reducer = {
  aws: awsReducer,
  approval: approvalReducer,
  topic: topicReducer,
  status: statusReducer,
  severity: severityReducer,
  risk: riskReducer,
  occurrence: occurrenceReducer,
  assessment: assessmentReducer,
  evaluation: evaluationReducer,
  masterdata: masterdataReducer,
  storage: storageReducer,
  report: reportReducer,
}

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === ("development" || "local" || "test"),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
