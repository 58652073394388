import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as reportService from "@/redux/services/reportService"
import { ReportBI, ReportSearch } from "@/models/report.model"
import { RootState } from "@/redux/store"

interface ReportState {
  reportBI: ReportBI[]
}

const initialState: ReportState = {
  reportBI: []
}

export const getReportBI = createAsyncThunk("report/bi", async () => {
  return await reportService.getReportBI()
})

export const postReportSummary = createAsyncThunk("report/summary", async (data: ReportSearch) => {
  return await reportService.postReportSummary(data)
})

export const postReportAll = createAsyncThunk("report/all", async (data: ReportSearch) => {
  return await reportService.postReportAll(data)
})

export const postReportCompletion = createAsyncThunk("report/completion", async () => {
  return await reportService.postReportCompletion()
})

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportBI.fulfilled, (state, action) => {
      state.reportBI = action.payload.result
    })
  },
})

export const biSelector = (store: RootState): ReportBI[] => store.report.reportBI

export default reportSlice.reducer