import { RootState } from "@/redux/store"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as assessmentService from "@/redux/services/assessmentService"

import { AssessmentDuplicate, AssessmentForm, AssessmentHistory, AssessmentUpdateForm, SearchData } from "@/models/assessments.model"
import { DashboardSearch, DashboardTable } from "@/models/evaluations.model"
import { PHASearchData, PHASubmitData } from "@/models/pha.model"

interface AssessmentState {
  dashboard: DashboardTable[]
}

const initialState: AssessmentState = {
  dashboard: []
}

export const postAssessmentData = createAsyncThunk("assessment/data", async (data: PHASearchData) => {
  return await assessmentService.postAssessmentData(data)
})

export const postAssessmentSearch = createAsyncThunk("assessment/search", async (data: SearchData) => {
  return await assessmentService.postAssessmentSearch(data)
})

export const postAssessment = createAsyncThunk("assessment/create", async (data: AssessmentForm) => {
  return await assessmentService.postAssessment(data)
})

export const postAssessmentDuplicate = createAsyncThunk("assessment/duplicate", async (data: AssessmentDuplicate) => {
  return await assessmentService.postAssessmentDuplicate(data)
})

export const postAssessmentHistory = createAsyncThunk("assessment/history", async (data: AssessmentHistory) => {
  return await assessmentService.postAssessmentHistory(data)
})

export const putAssessment = createAsyncThunk("assessment/update", async (data: AssessmentUpdateForm) => {
  return await assessmentService.putAssessment(data)
})

export const postDashboard = createAsyncThunk("assessment/dashboard", async (data: DashboardSearch) => {
  return await assessmentService.postDashboard(data)
})

export const sendReview = createAsyncThunk("assessment/review", async (data: PHASubmitData) => {
  return await assessmentService.sendReview(data)
})

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload.result
    })
  },
})

export const dashboardSelector = (store: RootState): DashboardTable[] => store.assessment.dashboard

export default assessmentSlice.reducer