import { ReportAllRes, ReportBIRes, ReportCompletionRes, ReportSearch } from "@/models/report.model"
import { PHAClient } from "@/utils/axios"

export const getReportBI = async (): Promise<ReportBIRes> => {
  const response = await PHAClient.get(`/api/report/bi`)
  return response.data
}

export const postReportSummary = async (data: ReportSearch): Promise<ReportBIRes> => {
  const response = await PHAClient.post(`/api/report/summary`, data)
  return response.data
}

export const postReportAll = async (data: ReportSearch): Promise<ReportAllRes> => {
  const response = await PHAClient.post(`/api/report/all`, data)
  return response.data
}

export const postReportCompletion = async (): Promise<ReportCompletionRes> => {
  const response = await PHAClient.post(`/api/report/completion`)
  return response.data
}