import axios from 'axios'
import { getSession } from 'next-auth/react'

import { SessionExtension } from '@/models/pha.model'

const PHAClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_API,
  headers: {
    'X-Auth-Key': process.env.NEXT_PUBLIC_AUTH_KEY!,
    'X-Token-Key': process.env.NEXT_PUBLIC_TOKEN_KEY!
  }
})

PHAClient.interceptors.request.use(async config => {
  const session = await getSession()
  const newsession = session as SessionExtension
  const accessToken = newsession?.accessToken

  config.headers["Authorization"] = `Bearer ${accessToken}`

  return config
})

export { PHAClient }