import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as evaluationService from "@/redux/services/evaluationService"
import { DashboardSearch, EvaluateApproveForm, EvaluateProductForm, ListProduct, MyProductReq, ReviewProductReq, UpdateCellEvaluation, UpdateEvaluateProductForm } from "@/models/evaluations.model"
import { PHASearchData } from "@/models/pha.model"
import { RootState } from "@/redux/store"

interface EvaluationState {
  listProduct: ListProduct[]
}

const initialState: EvaluationState = {
  listProduct: []
}

export const getProductList = createAsyncThunk("evaluation/listproduct", async () => {
  return await evaluationService.getProductList()
})

export const postEvaluateDashboard = createAsyncThunk("evaluation/dashboard", async (data: DashboardSearch) => {
  return await evaluationService.postEvaluateDashboard(data)
})

export const postEvaluateSearch = createAsyncThunk("evaluation/search", async (data: PHASearchData) => {
  return await evaluationService.postEvaluateSearch(data)
})

export const postEvaluateHistory = createAsyncThunk("evaluation/history", async (data: PHASearchData) => {
  return await evaluationService.postEvaluateHistory(data)
})

export const postEvaluateProduct = createAsyncThunk("evaluation/product", async (data: EvaluateProductForm) => {
  return await evaluationService.postEvaluateProduct(data)
})

export const postEvaluateRisk = createAsyncThunk("evaluation/risk", async (data: PHASearchData) => {
  return await evaluationService.postEvaluateRisk(data)
})

export const postEvaluateApproval = createAsyncThunk("evaluation/approval", async (data: PHASearchData) => {
  return await evaluationService.postEvaluateApproval(data)
})

export const postCellEvaluation = createAsyncThunk("evaluation/cell", async (data: PHASearchData) => {
  return await evaluationService.postCellEvaluation(data)
})

export const postEvaluateReview = createAsyncThunk("evaluation/review", async (data: UpdateEvaluateProductForm) => {
  return await evaluationService.postEvaluateReview(data)
})

export const putEvaluateUpdate = createAsyncThunk("evaluation/update", async (data: UpdateEvaluateProductForm) => {
  return await evaluationService.putEvaluateUpdate(data)
})

export const putEvaluateApprove = createAsyncThunk("evaluation/approve", async (data: EvaluateApproveForm) => {
  return await evaluationService.putEvaluateApprove(data)
})

export const putCellEvaluation = createAsyncThunk("evaluation/cell", async (data: UpdateCellEvaluation) => {
  return await evaluationService.putCellEvaluation(data)
})

export const postMyProduct = createAsyncThunk("evaluation/myproduct", async (data: MyProductReq) => {
  return await evaluationService.postMyProduct(data)
})

export const postReviewProduct = createAsyncThunk("evaluation/clone", async (data: ReviewProductReq) => {
  return await evaluationService.postReviewProduct(data)
})

const evaluationSlice = createSlice({
  name: "evaluation",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductList.fulfilled, (state, action) => {
      state.listProduct = action.payload.result
    })
  }
})

export const productSelector = (store: RootState): ListProduct[] => store.evaluation.listProduct

export default evaluationSlice.reducer