import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as riskService from "@/redux/services/riskService"
import { Risk } from "@/models/risks.model"
import { RootState } from "@/redux/store"

interface RiskState {
  risks: Risk[]
}

const initialState: RiskState = {
  risks: []
}

export const getRisk = createAsyncThunk("risk/all", async () => {
  return await riskService.getRisk()
})

const riskSlice = createSlice({
  name: "risk",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRisk.fulfilled, (state, action) => {
      state.risks = action.payload.result
    })
  },
})

export const riskSelector = (store: RootState): Risk[] => store.risk.risks

export default riskSlice.reducer