import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as topicService from "@/redux/services/topicService"
import { Topic } from "@/models/topics.model"
import { RootState } from "@/redux/store"

interface TopicState {
  topics: Topic[]
}

const initialState: TopicState = {
  topics: []
}

export const getTopic = createAsyncThunk("topic/all", async () => {
  return await topicService.getTopic()
})

const topicSlice = createSlice({
  name: "topic",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopic.fulfilled, (state, action) => {
      state.topics = action.payload.result
    })
  },
})

export const topicSelector = (store: RootState): Topic[] => store.topic.topics

export default topicSlice.reducer