import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as storageService from "@/redux/services/storageService"
import { FileForm, FileInfo, FileRemove } from "@/models/storages.model"
import { PHASearchData } from "@/models/pha.model"

interface StorageState {
  files: FileInfo[]
}

const initialState: StorageState = {
  files: []
}

export const postFileData = createAsyncThunk("upload/data", async (data: PHASearchData) => {
  return await storageService.postFileData(data)
})

export const removeFile = createAsyncThunk("upload/remove", async (data: FileRemove) => {
  return await storageService.removeFile(data)
})

export const postUpload = createAsyncThunk("upload/create", async (data: FileForm) => {
  return await storageService.postUpload(data)
})

const storageSlice = createSlice({
  name: "storage",
  initialState: initialState,
  reducers: {},
})

export default storageSlice.reducer